<template>
  <section v-show="details.admin_remarks" class="admin-remarks">
    <div class="flex items-center">
      <svg
        class="w-8 h-8 text-oGreen"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div class="text-14px">
        <span class="mx-1 font-medium text-charcoal">Admin remarks:</span>
        <span class="text-charcoal">{{ details.admin_remarks }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AdminRemarksBlock',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.admin-remarks {
  border: solid 1px #e4e4e4;
  background-color: #fefdff;
  border-radius: 22px;
  @apply w-full px-5 py-6 mt-6 bg-white rounded-lg;
}
</style>
